import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function LWVUtahResearch() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const buttonContainerStyle = isMobile ? { display: 'flex', flexDirection: 'column', alignItems: 'center' } : { textAlign: 'center' };

    const gridContainerStyle = {
        display: 'grid',
        gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', // One column on mobile, two columns otherwise
        textAlign: 'center',
        gap: '5px', // Space between columns
    };

    return (
        <div>
            <div className="text-content">
                <h1 style={{ textAlign: 'center' }}>LWV Utah Research</h1>
                <h3 style={{ textAlign: 'center' }}>Explore news coverage of key issues to LWV-UT, all in one place.</h3>
                <h3 style={{ textAlign: 'center' }}>Click on an image to get started. To return to this page, click on the LWV-UT logo.</h3>
                <br/>
            </div>
            <div>
                <div style={gridContainerStyle}>
                    <div>
                        {/* Image dimensions need to be 1800 x 1350 px or in that ratio */}
                        <h3>Climate Change</h3>
                        <Link to="/lwvut/climate_change" className="btn-link">
                            <img src="https://i.imgur.com/GwTmXmc_d.webp?maxwidth=1520&fidelity=grand" style={{ maxWidth: '90%', height: 'auto' }} />
                        </Link>
                        <br /><br />
                        <h3>U.S. Health Care</h3>
                        <Link to="/lwvut/us_health_care" className="btn-link">
                            <img src="https://i.imgur.com/QhV2OTX_d.webp?maxwidth=1520&fidelity=grand" style={{ maxWidth: '90%', height: 'auto' }} />
                        </Link>
                        <br /><br />
                        <h3>U.S. Election Administration</h3>
                        <Link to="/lwvut/us_election_administration" className="btn-link">
                            <img src="https://i.imgur.com/S3xSxij_d.webp?maxwidth=1520&fidelity=grand" style={{ maxWidth: '90%', height: 'auto' }} />
                        </Link>
                        <br /><br />
                    </div>
                    <div>
                        <h3>U.S. Education</h3>
                        <Link to="/lwvut/us_public_education" className="btn-link">
                            <img src="https://i.imgur.com/glrQrt4_d.webp?maxwidth=1520&fidelity=grand" style={{ maxWidth: '90%', height: 'auto' }} />
                        </Link>
                        <br /><br />
                        <h3>U.S. Public Lands</h3>
                        <Link to="/lwvut/us_public_lands" className="btn-link">
                            <img src="https://i.imgur.com/MEjeVvv_d.webp?maxwidth=1520&fidelity=grand" style={{ maxWidth: '90%', height: 'auto' }} />
                        </Link>
                        <br /><br />
                    </div>
                </div>
            </div>
        </div>
    );
}
